{
  "titleHome": "Benutzereinstellungen",
  "titleWelcome": "Willkommen zu den Benutzereinstellungen",
  "titleDebug": "Debug - Benutzereinstellungen",
  "titleError": "Fehler - Benutzereinstellungen",
  "titleSearch": "Suche - Benutzereinstellungen",
  "titleSearchRestriction": "Einschränkung der Suche - Benutzereinstellungen",
  "titleAutoSuggest": "Vorschlagsliste - Benutzereinstellungen",
  "titleHitlist": "Trefferliste - Benutzereinstellungen",
  "titlePersonalization": "Personalisierung - Benutzereinstellungen",
  "titleDocumentView": "Dokumentansicht - Benutzereinstellungen",
  "titleLayout": "Layout - Benutzereinstellungen",
  "titleAccessibility": "Barrierefreiheit - Benutzereinstellungen",
  "titlePortals": "Portale - Benutzereinstellungen",
  "titleLanguage": "Sprache - Benutzereinstellungen",
  "titleNotifications": "Benachrichtigungen - Benutzereinstellungen",
  "titleNewsletters": "Newsletter - Benutzereinstellungen",
  "titlemagazines": "Zeitschriften - Benutzereinstellungen",
  "titleModuleContents": "Modulinhalte - Benutzereinstellungen",
  "titleSearchJobs": "Suchaufträge - Benutzereinstellungen",
  "titleProtection": "Historie - Benutzereinstellungen",
  "titleDataProtection": "Datenschutz - Benutzereinstellungen",
  "titlePersonalData": "Persönliche Daten - Benutzereinstellungen",
  "personalSettings": "Benutzereinstellungen",
  "salutation": "Guten Tag",
  "settingsIntro": "dieser Bereich bietet Ihnen die Möglichkeit, beck-online.DIE DATENBANK an Ihre persönlichen Bedürfnisse anzupassen. Die Einstellungsänderungen wirken sich nach dem Speichern sofort aus und bleiben auch unabhängig vom erneuten Ein- und Ausloggen bestehen. Sie können die Einstellungen jederzeit und so oft Sie wollen ändern.",
  "searchTitle": "Suche",
  "searchSettings": "Sucheinstellungen",
  "suggestionList": "Vorschlagsliste",
  "hitlistTitle": "Trefferliste",
  "personalizationText": "Personalisierung",
  "documentView": "Dokumentansicht",
  "keyBoard": "Tastatur",
  "layout": "Layout",
  "languageTitle": "Sprache",
  "portalTitle": "Portale",
  "highlighting": "Highlighting",
  "notificationText": "Benachrichtigungen",
  "newsletter": "Newsletter",
  "searchOrder": "Suchaufträge",
  "moduleContent": "Modulinhalte",
  "magazines": "Zeitschriften",
  "privacyTitle": "Datenschutz",
  "historyTitle": "Historie",
  "documentsCalled": "Aufgerufene Dokumente",
  "dataTitle": "Persönliche Daten",
  "settingsTitle": "Einstellungen",
  "selectAll": "Alle auswählen",
  "deselectAll": "Alle abwählen",
  "changesSaved": "Änderungen gespeichert!",
  "unexpectedErrorWhileSaving": "Unerwarteter Fehler beim Speichern der Änderungen",
  "searchRestrictionFilterAll": "Alle",
  "searchRestrictionFilterModule": "Module",
  "searchRestrictionFilterCooperation": "Kooperationspartner",
  "searchRestrictionFilterLaw": "Rechtsgebiete",
  "searchRestrictionBayernrecht": "Einschränkung der Suche und des Inhalts nur auf Normen Bayerns und des Bundes",
  "searchRestrictionBayernrechtDescription": "Standardmäßig werden Ihnen nur die Normen Bayerns und des Bundes angezeigt und es werden auch nur diese Dokumente durchsucht. Alternativ können Sie die Suche und den Inhalt ohne Einschränkung des Geltungsbereichs durchführen.",
  "searchRestrictionBayernrechtRestricted": "Suche und Inhalt auf Normen Bayerns und des Bundes einschränken",
  "searchRestrictionBayernrechtNotRestricted": "Geltungsbereich nicht einschränken",
  "searchRestrictionMyModules": "Einschränkung der Suche und des Inhalts auf abonnierte Module",
  "searchRestrictionMyModulesDescription": "Standardmäßig werden Ihnen alle Inhalte aus {0} angezeigt und es werden alle Dokumente durchsucht. Dies gewährleistet Ihnen umfangreiche Rechercheergebnisse, insbesondere im Hinblick auf angrenzende Rechtsgebiete. Dokumente, die nicht von Ihrem Abonnement umfasst sind, können Sie bei Bedarf und auf gesonderten Hinweis durch Einzeldokumentkauf erwerben. Alternativ können Sie die Suche und den Inhalt nur auf die abonnierten Dokumente einschränken indem Sie „Mein {0}“ aktivieren. Auch hier werden Ihnen aber einschlägige Parallelfundstellen nicht vorenthalten.",
  "searchRestrictionMyModulesDescription-pers": "Standardmäßig wird Ihre Suche auf das Personal-Lexikon beschränkt. Um die Suche auf alle Inhalte aus beck-personal-portal zu erweitern, wählen Sie bitte die die Option 'alle Inhalte anzeigen und durchsuchen'. Dies gewährleistet Ihnen umfangreiche Rechercheergebnisse, insbesondere im Hinblick auf angrenzende Rechtsgebiete. Dokumente, die nicht von Ihrem Abonnement umfasst sind, können Sie bei Bedarf und auf gesonderten Hinweis durch Einzeldokumentkauf erwerben. Alternativ können Sie die Suche und den Inhalt nur auf die abonnierten Dokumente einschränken indem Sie die Option 'Suche und Inhalt auf abonnierte Module einschränken (Mein Personal-Portal)' aktivieren. Auch hier werden Ihnen aber einschlägige Parallelfundstellen nicht vorenthalten.",
  "searchRestrictionOnlyLexicon": "Suche auf das Personal-Lexikon beschränken",
  "searchRestrictionShowAllContent": "alle Inhalte anzeigen und durchsuchen",
  "searchRestrictionMyModuleOptionRestrictionOfModule": "Suche und Inhalt auf abonnierte Module einschränken (Mein {0})",
  "searchRestrictionModulesAndLexicon": "Suche auf abonnierte Module (Mein Personal-Portal) und auf das Personal-Lexikon beschränken. Inhalte werden ebenfalls auf abonnierte Module eingeschränkt.",
  "searchRestrictionMyModuleOptionInfoNotification": "Hinweismeldung zur Einschränkung der Suche auf abonnierte Module anzeigen",
  "searchRestrictionMyCooperation": "Einschränkung der Suche auf bestimmte Kooperationspartner",
  "searchRestrictionMyCooperationDescription": "Standardmäßig können Sie in beck-online verlagsunabhängig über alle vorhandenen Dokumente recherchieren. Mit der unten genannten Auswahl können Sie die Suche auf bestimmte Kooperationspartner einschränken. Die verlagseigenen C.H. Beck-Dokumente bleiben unberührt. Auch die Funktion mein beck-online wird damit nicht beeinflusst.",
  "searchRestrictionSelectAllCooperations": "alle Kooperationspartner auswählen",
  "searchRestrictionDeselectAllCooperations": "alle Kooperationspartner abwählen",
  "searchRestrictionCustomRestrictionText": "oder wählen Sie Ihre Einschränkungen individuell:",
  "searchRestrictionMyRechtsgebiete": "Einschränkung der Suche auf bestimmte Rechtsgebiete",
  "searchRestrictionMyRechtsgebieteDescription": "Standardmäßig können Sie in beck-online rechtsgebietunabhängig über alle vorhandenen Dokumente recherchieren. Mit der unten genannten Auswahl können Sie die Suche auf bestimmte Rechtsgebiete einschränken.",
  "suggestTitle": "Vorschlagsliste bei Eingabe eines Suchwortes",
  "suggestDescription": "Standardmäßig klappt beim Eintippen der ersten Buchstaben eines Suchbegriffes eine Vorschlagsliste auf, die Ihnen bei der Vervollständigung des Wortes helfen kann. Die Liste ist sortiert nach Relevanz und Häufigkeit der Sucheingaben, könnte Ihnen also zu interessanten Treffern verhelfen. Alternativ können Sie die Vorschlagsliste auch ausblenden. Sofern unter {0} aktiviert, wird auch ihre persönliche Suchhistorie als Vorschlag angezeigt.",
  "suggestDescriptionLink": "der Historie",
  "suggestActivate": "Vorschlagsliste anzeigen",
  "suggestDeactivate": "Vorschlagsliste ausblenden",
  "searchHitlistFilterAll": "Alle",
  "searchHitlistFilterKeywords": "Schlagworte",
  "searchHitlistFilterTopResults": "Top-Treffer",
  "searchHitlistFilterSorting": "Sortierung",
  "searchHitlistFilterContexts": "Kontexte",
  "searchHitlistFilterNumberOfResults": "Anzahl Treffer",
  "keywordsTitle": "Schlagworte zur Trefferliste",
  "keywordsDescription": "Standardmäßig erhalten Sie mit der Trefferliste die 10 wichtigsten Schlagworte, die in den gesuchten Dokumenten am Häufigsten vorkommen. Je dicker und größer die Schlagworte dargestellt werden, umso häufiger kommen sie in den Dokumenten vor. Es werden u.a. auch Phrasen, wichtige Gerichtsurteile oder Autorennamen vorgeschlagen. Eine Trefferliste kann so schnell und effektiv eingeschränkt werden. Alternativ können Sie die Schlagworte auch ausblenden.",
  "keywordsShow": "Schlagworte anzeigen",
  "keywordsHide": "Schlagworte ausblenden",
  "topResultsTitle": "Top-Treffer",
  "topResultsDescription": "Über die Top-Treffer erhalten Sie eine Übersicht über die wichtigsten Paragrafen passend zu Ihrer Sucheingabe. Diese werden statistisch ermittelt und erleichtern die Orientierung bei unklarer Terminologie oder Anspruchsgrundlage.",
  "topResultsShow": "Top-Treffer anzeigen",
  "topResultsHide": "Top-Treffer ausblenden",
  "sortingTitle": "Sortierung der Trefferliste",
  "sortingDescription": "Standardmäßig werden die Trefferlisten nach Relevanz sortiert, d.h. Sie finden am Anfang der Trefferliste die Dokumente, in denen der Suchbegriff die höchste Gewichtung hat. Alternativ können Sie die Treffer auch nach Datum sortieren, das neueste Dokument steht dann an erster Stelle.",
  "sortingRelevance": "Trefferliste nach Relevanz sortieren",
  "sortingDate": "Trefferliste nach Datum sortieren",
  "contextsTitle": "Anzeige der Dokumentkontexte in einer Trefferliste",
  "contextsDescription": "Standardmäßig sind die Dokumentkontexte der einzelnen Treffer verborgen, Sie können sich diese bei Bedarf einzeln oder auch für die gesamte Trefferseite anzeigen lassen. Interessieren Sie regelmäßig die Dokumentkontexte für alle Treffer, so bietet sich die Alternativeinstellung an.",
  "contextsHide": "Dokumentkontexte für alle Treffer verbergen",
  "contextsShow": "Dokumentkontexte für alle Treffer einblenden",
  "numberOfResultsTitle": "Anzahl der Ergebnisse",
  "numberOfResultsDescription": "Standardmäßig werden Ihnen bei den Inhaltsübersichten und Trefferlisten jeweils 20 Ergebnisse auf einer Seite angezeigt, danach müssen Sie weiterblättern. Alternativ können Sie sich aber mehr Ergebnisse anzeigen lassen.",
  "numberOfResultsTOC": "Inhaltsübersichten",
  "numberOfResultsResultList": "Trefferliste",
  "personalDataTitle": "Persönliche Daten",
  "name": "Ihr Name",
  "mailAddress": "Ihre Mailadresse",
  "personalDataDescription": "Zur Änderung Ihrer persönlichen Daten schicken Sie bitte eine E-Mail an {0}.",
  "personalDataIntro": "Bitte prüfen Sie Ihre persönlichen Daten und korrigieren Sie diese bei Bedarf, indem Sie auf „Nutzerdaten aktualisieren“ klicken. Sie können diese Informationen jederzeit an dieser Stelle wieder ändern.",
  "personalDataSalutation": "Anrede",
  "personalDataMister": "Herr",
  "personalDataMiss": "Frau",
  "personalDataDivers": "Divers",
  "personalDataAcademic": "Titel / Akademischer Grad",
  "personalDataAcademicEmpty": "Bitte wählen",
  "personalDataFirstName": "Vorname",
  "personalDataLastName": "Nachname",
  "personalDataEmail": "E-Mail-Adresse",
  "personalDataInfo": "Es wird empfohlen, alle Felder zu befüllen.",
  "personalDataUpdate": "Nutzerdaten aktualisieren",
  "personalDataReset": "zurücksetzen",
  "personalDataChangePassword": "Passwort ändern",
  "personalDataChangePasswordDescription": "Wenn Sie Ihr Passwort ändern wollen, dann kommen Sie über folgenden Link zu den Kontoeinstellungen: ",
  "organisationDataTitle": "Organisationsdaten",
  "organisation": "Ihre Organisation",
  "organisationStructure": "Ihre Organisationsstruktur",
  "organisationContacts": "Ihre Ansprechpartner",
  "organisationEmails": "Support E-Mails",
  "organisationWebsites": "Support Websites",
  "organisationSupportType_Fachlich": "Fachlicher Support",
  "organisationSupportType_Technisch": "Technischer Support",
  "organisationUse2FaTitle": "2-Faktor-Authentifizierung",
  "organisationUse2FaDescription": "Ich möchte Änderungen an 2-Faktor-Authentifizierung vornehmen.",
  "organisationUse2FaButton": "Ändern",
  "privacyFilterAll": "Alle",
  "privacyFilterPersonalSearchHistory": "Suchhistorie",
  "privacyFilterViewedDocuments": "Aufgerufene Dokumente",
  "personalSearchHistoryTitle": "Persönliche Suchhistorie",
  "personalSearchHistoryDescription": "Wenn aktiviert, können Sie unter 'Mein beck-online' (als persönlich eingeloggter Nutzer) auf Ihre {0} zugreifen. So können Sie bereits durchgeführte Suchen erneut tätigen oder auch nachträglich noch einen Suchauftrag anlegen. Die Suchhistorie wird auch in den Suchvorschlägen angezeigt, wenn die entsprechende Option unter 'Benutzereinstellungen', '{1}' aktiviert ist.",
  "personalSearchHistoryLink": "Suchhistorie",
  "personalSearchHistoryActivate": "Suchhistorie speichern",
  "personalSearchHistoryDeactivate": "Suchhistorie nicht speichern",
  "personalSearchHistoryDisableDlgTitle": "Suchhistorie löschen",
  "personalSearchHistoryDisableDlgText": "Wenn Sie die Option auf 'Suchhistore nicht speichern' setzen, wird Ihr bisheriger Suchverlauf gelöscht. Wollen Sie fortfahren?",
  "personalSearchHistoryDisableDlgOk": "Option ändern, Suchhistorie löschen",
  "personalSearchHistoryDisableDlgCancel": "Option nicht ändern, Suchhistorie beibehalten",
  "deleteSearchHistoryDescription": "Ihren bisherigen Suchverlauf können Sie direkt in der {0} löschen.",
  "viewedDocumentsTitle": "Aufgerufene Dokumente",
  "viewedDocumentsDescription": "Wenn aktiviert, können Sie unter 'Mein beck-online' (als persönlich eingeloggter Nutzer) jederzeit auf Ihre {0} zurückgreifen. Sie können an dieser Stelle entscheiden, ob Ihnen diese Dokumente weiterhin angezeigt werden sollen. Diese Einstellung bewirkt lediglich die Anzeige der Dokumente. Zu Abrechnungszwecken speichern wir Ihren Dokumentenverlauf weiterhin für 90 Tage.",
  "viewedDocumentsLink": "zuletzt aufgerufenen Dokumente",
  "viewedDocumentsShow": "Aufgerufene Dokumente anzeigen",
  "viewedDocumentsHide": "Aufgerufene Dokumente ausblenden",
  "searchDocumentViewFilterAll": "Alle",
  "searchDocumentViewFilterCopyFunction": "Kopierfunktion",
  "searchDocumentViewFilterHighlighting": "Highlighting",
  "copyFunctionTitle": "Kopierfunktion mit Zitiervorschlag",
  "copyFunctionDescription": "Standardmäßig wird beim Markieren von Text in einem Dokument ein Icon eingeblendet, das den Dialog der Kopierfunktion mit Zitiervorschlag öffnet. Alternativ kann diese Funktion deaktiviert werden.",
  "copyFunctionShow": "Kopierfunktion mit Zitiervorschlag beim Markieren von Text einblenden",
  "copyFunctionHide": "Kopierfunktion mit Zitiervorschlag beim Markieren von Text nicht einblenden",
  "highlightingTitle": "Highlighting",
  "highlightingDescription": "Hier können Sie das Highlighting auf Dokumentenebene aktivieren oder deaktivieren.",
  "highlightingActivate": "Highlighting aktivieren",
  "highlightingDeactivate": "Highlighting deaktivieren",
  "adjustableColumnsReset": "Spaltenbreiten wurden zurückgesetzt",
  "adjustableColumnsTitle": "Anpassbare Spalten",
  "adjustableColumnsDescription": "Mit einem Klick auf den Button setzen Sie die Breite der anpassbaren Spalten auf den Standardwert zurück.",
  "adjustableColumnsButton": "Spaltenbreiten zurücksetzen",

  "toggleBeckChatTitle": "beck-chat",
  "toggleBeckChatDescription": "Hier können Sie entscheiden, ob Sie den beck-chat dauerhaft ein- oder ausblenden wollen.",
  "toggleBeckChatShow": "beck-chat einblenden",
  "toggleBeckChatHide": "beck-chat ausblenden",

  "keyboardShortcutsTitle": "Tastaturkombinationen",
  "keyboardShortcutsTooltip": "Klicken Sie hier um zur Übersichtsseite der Tastaturkombinationen zu gelangen",
  "keyboardShortcutsDescription": "Hier können Sie die Tastaturkombinationen aktivieren oder deaktivieren.",
  "keyboardShortcutsEnable": "Tastaturkombinationen aktivieren",
  "keyboardShortcutsDisable": "Tastaturkombinationen deaktivieren",
  "portalChangeTitle": "Portalwechsel",
  "portalChangeDescription": "Für Sie würde sich ein Wechsel in das Portal 0 lohnen. Möchten Sie beim Anmelden in das Portal 0 automatisch wechseln?",
  "portalChangeText": "Beim Anmelden in das Portal 0 wechseln.",
  "portalStay": "Beim Anmelden im aktuellen Portal bleiben.",
  "portalAsk": "Nachfragen welches Portal genutzt werden soll.",
  "languageChangeTitle": "Sprachauswahl",
  "languageDescription": "Hier können Sie die Sprache der beck-online Oberfläche wählen.",
  "languageChange": "Sprache wählen:",
  "changeMailAddressDescription": "Ihre Auswahl wird derzeit an {0} verschickt.",
  "changeMailAddressDescriptionNoMail": "Sie haben derzeit keine E-Mail-Adresse hinterlegt.",
  "newsletterFilterAll": "Alle",
  "newsletterFilterNewsletters": "Newsletter",
  "newsletterFilterCampaigns": "Aktionen",
  "newsletterFilterChangeMail": "Mailadresse",
  "newslettersTitle": "Newsletter",
  "newslettersDescription1": "Auf ",
  "newsletterLinkText": "dieser Seite",
  "newslettersDescription2": " können Sie künftig den beck-aktuell-Newsletter registrieren",
  "campaignsTitle": "Aktionen und neue Funktionen",
  "campaignsDescription": "Gelegentlich werden wir Sie auf neue Funktionen und Module hinweisen oder ermöglichen, bestimmte Inhalte kostenlos zum Test aufzuschalten. Diese Aktionen sind unverbindlich und können jederzeit ignoriert werden. Sie können der Anzeige von Aktionen generell widersprechen:",
  "campaignsShow": "Aktionen anzeigen",
  "changeMailAddressTitle": "E-Mail-Adresse ändern",
  "changeMailAddressLink": "Zum Ändern Ihrer E-Mail-Adresse schicken Sie bitte eine E-Mail an {0}.",
  "changeMailAddressLinkSso": "Zum Ändern Ihrer E-Mail-Adresse klicken Sie bitte {0}.",
  "changeMailAddressLinkSsoHere": "hier",
  "lastNotificationDate": "(Letzte Benachrichtigung: {0})",
  "typeOfNotificationNone": "Keine",
  "typeOfNotificationOnlyJournals": "Nur Zeitschriften",
  "typeOfNotificationOnlyBooks": "Nur Bücher",
  "typeOfNotificationJournalsAndBooks": "Bücher und Zeitschriften",
  "moduleContentsFilterAll": "Alle",
  "moduleContentsFilterModules": "Module",
  "moduleContentsFilterInterval": "Versand",
  "moduleContentsTitle": "Benachrichtigungen für neue Modulinhalte",
  "moduleContentsDescription": "Lassen Sie sich über neue Bücher und Zeitschriften in Ihren abonnierten Modulen per E-Mail benachrichtigen:",
  "moduleContentsModuleName": "Modulname",
  "moduleContentsTypeOfNotification": "Benachrichtigungsart",
  "notificationIntervalTitle": "Versand",
  "notificationIntervalDescription": "Legen Sie fest, wie häufig Sie über neue Modulinhalte benachrichtigt werden wollen:",
  "notificationIntervalDaily": "Täglich",
  "notificationIntervalWeekly": "Wöchentlich",
  "notificationIntervalMonthly": "Monatlich",
  "notificationModule0": "beck-online (insgesamt)",
  "magazineNotificationTitle": "Benachrichtigungen für neue Zeitschriften",
  "magazineNotificationDescription": "In der Zeitschriftenübersicht können Sie kostenlos Benachrichtigungen über neu in beck-online gestellte Hefte abonnieren.",
  "magazineNotificationLink": "Zur Zeitschriftenübersicht",
  "magazineNotificationCurrentJournals": "Sie erhalten derzeit Benachrichtigungen für:",
  "magazineNotificationNoJournals": "Sie haben derzeit keine Benachrichtigungen aktiviert.",
  "magazineNotificationUnsubscribe": "Abbestellen",
  "magazineNotificationUnsubscribeTitle": "Benachrichtigung abbestellen",
  "magazineNotificationUnsubscribeDescription": "Wollen Sie die Benachrichtigungen für die Zeitschrift '{0}' wirklich abbestellen?",
  "magazineNotificationUnsubscribeAccept": "Ja, abbestellen",
  "magazineNotificationUnsubscribeDeny": "Abbrechen",
  "searchAlertsTitle": "Meine Suchaufträge",
  "searchAlertsDescription": "Zum Anlegen von Suchaufträgen führen Sie eine Suche aus und klicken dann auf den Button 'Suche speichern'.",
  "searchAlertsHasJobs": "Zum Sortieren nach einer beliebigen Spalte bitte in der gewünschten Spalte auf den Spaltenkopf klicken.",
  "searchAlertsNoJobs": "Sie haben derzeit keine Suchaufträge beauftragt.",
  "searchAlertsCreatedOn": "Angelegt am",
  "searchAlertsName": "Name",
  "searchAlertsNote": "Notiz",
  "searchAlertsSearchTerms": "Suchkriterien",
  "searchAlertsUntil": "Benachrichtigung bis",
  "searchAlertsSearchNow": "Jetzt suchen",
  "searchAlertsEdit": "Auftrag bearbeiten",
  "searchAlertsDelete": "Auftrag löschen",
  "searchAlertsConfirmDeleteTitle": "Suchauftrag löschen",
  "searchAlertsConfirmDeleteDescription": "Wollen Sie den Suchauftrag '{0}' wirklich löschen?",
  "searchAlertsConfirmDeleteAccept": "Ja, Suchauftrag löschen",
  "searchAlertsConfirmDeleteDeny": "Abbrechen",
  "searchAlertsDeletedMsg": "Suchauftrag '{0}' wurde erfolgreich gelöscht",
  "searchAlertsStatus_1": "Der Suchauftrag wurde deaktiviert, da kein passendes Benutzerkonto gefunden wurde.",
  "searchAlertsStatus_2": "Der Suchauftrag wurde deaktiviert, da das zugehörige Benutzerkonto gesperrt wurde.",
  "searchAlertsStatus_3": "Der Suchauftrag wurde deaktiviert, da die bei dem Benutzerkonto hinterlegte Mailadresse nicht valide ist.",
  "searchAlertsStatus_4": "Der Suchauftrag wurde deaktiviert, da der Benutzer keine gültigen Modulrechte besitzt.",
  "searchAlertsStatus_5": "Der Suchauftrag wurde deaktiviert, da der Benutzer oder eine zugeordnete Organisation gelöscht wurde.",
  "restrictionSearch": "Einschränkung der Suche",
  "newsletters": "Newsletter",
  "accessibility": "Barrierefreiheit",
  "language": "Sprache",
  "navItemHomepage": "Zur Datenbank",
  "navItemBuy": "Bestellen",
  "navItemFiles": "Akten",
  "navItemHelp": "Hilfe",
  "navItemLogin": "Anmelden",
  "navItemLogout": "Abmelden",
  "navItemAccount": "Konto",
  "navItemAccountSettings": "Kontoeinstellungen",

  "footerImprint": "Impressum",
  "footerAgb": "AGB",
  "footerDataProtection": "Datenschutzerklärung",
  "footerDataProtectionSettings": "Datenschutz-Einstellungen",
  "footerCareerText": "Karriere",
  "footerFacebook": "beck-online auf Facebook",
  "footerLinkedIn": "beck-online auf LinkedIn",
  "footerTwitter": "beck-online auf X",
  "footerRss": "beck-online Fachnews Rss-Feed",

  "errorTitle": "Die angeforderte Seite existiert nicht.",
  "errorDescription": "Zurück zu den {0}",
  "errorBackToHome": "Benutzereinstellungen",
  "welcomeTitle": "Herzlich willkommen zu",
  "welcomeTitle2": "beck-online.DIE DATENBANK",
  "welcomeSettings": "Hier können Sie beck-online an Ihre individuellen Bedürfnisse anpassen.",
  "welcomeHomepageRecherche": "Recherche",
  "welcomeHomepageRechercheDescription": "Starten Sie von der Homepage aus in Ihre Fachinformations- recherche.",
  "welcomeEditData": "Benutzerdaten",
  "welcomeEditDataDescription": "Hier können Sie Ihre Benutzerdaten einsehen und Änderungen veranlassen.",
  "welcomeWebinar": "Webinare",
  "welcomeWebinarDescription": "Melden Sie sich kostenfrei zu unseren Online-Schulungen an und erfahren Sie viele wertvolle Recherchetipps.",
  "welcomeTitleDescription": "Wir freuen uns, Sie das erste Mal bei uns begrüßen zu dürfen. Wenn Sie unmittelbar in die Fachinformationsrecherche einsteigen möchten, können Sie über „Recherche“ starten. Wir empfehlen Ihnen aber auch einen Blick in die „Einstellungen“, wo Sie viele Möglichkeiten haben, beck-online an Ihre individuellen Bedürfnisse anzupassen. Zudem halten wir unterschiedliche Webinare bereit, mit denen Sie sich in der Nutzung unserer Fachanwendung von Kolleginnen und Kollegen unserer Kundenserviceteams schulen lassen können.",
  "welcomeGreetings": "Viele Grüße",
  "welcomeBeckTeam": "Ihr beck-online-Team",
  "welcomeModuleTitle": "Ihre Inhalte",
  "welcomeModuleDesc": "Hier können Sie direkt zu den von Ihnen abonnierten Inhalten gelangen.",
  "validationErrorEmail": "Bitte geben Sie ein gültige E-Mail-Adresse ein.",
  "mailToKscSubject": "Änderung Daten von Benutzer {0}"
}
